import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AuthStore} from "../../../../home-layout/common/store/auth-store.service";
import {LibSnackbarService} from "../../../../../tal-library/snackbar/lib-snackbar/common/service/lib-snackbar.service";
import {
    LibSnackBarConfig,
    SNACK_BAR_TYPE
} from "../../../../../tal-library/snackbar/lib-snackbar/common/model/lib-snackbar.model";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    private requestCount: number = 0;
    private tokenizedRequest: HttpRequest<any>;

    constructor(private authStore: AuthStore,
                private libSnackbarService: LibSnackbarService,
                private loadingBarService: LoadingBarService) {
    }

    intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCount++;
        this.loadingBarService.useRef().start();
        this.tokenizedRequest = request.clone();

        return this.handleRequest(httpHandler)
            .pipe(catchError((error: any) => {
                this.handleError(error);
                return throwError(error);
            }));
    }

    private handleRequest(httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        return httpHandler.handle(this.tokenizedRequest).pipe(
            finalize(() => {
                this.requestCount--;
                this.requestCount === 0 ? this.loadingBarService.useRef().stop() : {};
            }));
    }

    private handleError(error: any): void {
        if (error.status === HttpStatusCode.Forbidden) {
            this.authStore.logout();
        }
        this.libSnackbarService.openSnackBar(new LibSnackBarConfig('Oops! Something went wrong.', SNACK_BAR_TYPE.DANGER));
    }
}
