import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import {TokenService} from "../../../authentication/common/service/token.service";
import {LibSnackbarService} from "../../../../tal-library/snackbar/lib-snackbar/common/service/lib-snackbar.service";
import {ROUTE} from "../constant/routing.constant";
import {
    LibSnackBarConfig,
    SNACK_BAR_TYPE
} from "../../../../tal-library/snackbar/lib-snackbar/common/model/lib-snackbar.model";

@Injectable({
    providedIn: 'root'
})
export class ExceptionService {

    private skipErrors = [];

    constructor(private router: Router,
                private tokenService: TokenService,
                private libSnackbarService: LibSnackbarService) {
    }

    catchError = (error) => {
        this.handleStatusCode(error);
        return throwError(error);
    };

    private handleStatusCode(error) {
        switch (error?.status) {
            case 401:
                this.tokenService.handle401();
                break;
            case 403:
                this.handle403(error);
                break;
            default:
                this.showErrorMessage(error);
                break;
        }
    }

    private redirectToDashboard(error) {
        this.showErrorMessage(error);
        this.router.navigate([ROUTE.SLASH + ROUTE.DASHBOARD]).then(res => {
        });
    }

    private showErrorMessage(error) {
        const displayText = error?.error?.errorCode?.displayText;
        if (!this.skipErrors.includes(displayText)) {
            this.libSnackbarService.openSnackBar(new LibSnackBarConfig(this.getErrorMessage(displayText), SNACK_BAR_TYPE.DANGER));
        }
    }

    private getErrorMessage(displayText?: string) {
        return displayText
            ? displayText
            : 'Something went wrong, please contact admin';
    }

    private handle403(error: any): void {
        if (error?.error?.errorCode?.code) {
            this.showErrorMessage(error);
        } else {
            this.tokenService.handle403();
        }
    }
}
