import {PAYMENT_CONSTANTS} from "../app/talenlio-hireos/home-layout/common/constant/payment-constant";
import {OPEN_AI_CONSTANTS} from "../app/talenlio-hireos/home-layout/common/constant/open-ai";

export const environment = {
    name: 'prod',
    production: true,
    baseApiUrl: 'https://api.talenlio.com/api',
    firebase: {
        apiKey: "AIzaSyCaQJ3Djb_x2u5oW87cpK7Eh5ieetlRfYg",
        authDomain: "production-talenlio.firebaseapp.com",
        databaseURL: "https://production-talenlio-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "production-talenlio",
        storageBucket: "production-talenlio.appspot.com",
        messagingSenderId: "929235936063",
        appId: "1:929235936063:web:28e2601529d9a52ec6298a",
        measurementId: "G-5ZXZKH4VKT",
        vapidKey: "BA4WRBtVVscgoUX-_YbfENe3zmpXDRd0CCjPArVV3cwJQ-sbtdda6hQNQ82Kek43EYoF_dmEg2jQWkAj8xeS-gg"
    },
    edgeFirebase: {
        databaseURL: "https://production-talenlio-edge.europe-west1.firebasedatabase.app/",
    },
    artificialIntelligenceFirebase: {
        databaseURL: "https://production-talenlio-artificial-intelligence.europe-west1.firebasedatabase.app/",
    },
    publicApiKey: '089b37b7-3a81-424c-addf-2b162ca3158d',
    baseAppUrl: 'https://hireos.talenlio.com',
    hireOsBaseAppUrl: 'https://hireos.talenlio.com',
    standoutBaseAppUrl: 'https://standout.talenlio.com',
    organizaBaseAppUrl: 'https://orgniza.talenlio.com',
    showcaseBaseAppUrl: 'https://showcase.talenlio.com',
    payment: PAYMENT_CONSTANTS.STRIPE,
    stripeProperties: {
        publishableKey: PAYMENT_CONSTANTS.PUBLISHABLE_KEY,
        pricingTableId: PAYMENT_CONSTANTS.PRICING_TABLE_ID
    },
    openAiProperties: {
        apiUrl: OPEN_AI_CONSTANTS.API_URL,
        accessToken: OPEN_AI_CONSTANTS.ACCESS_TOKEN,
        model: OPEN_AI_CONSTANTS.MODEL
    },
    signUpBatchId: 'htpc7TAo',
    retoolConfig: {
        dashboard: {
            driveReportsUrl: 'https://ilmtec.retool.com/p/placement-drive-report?&placementDriveId=',
            organizaStatisticsReportUrl: 'https://ilmtec.retool.com/p/organiza-statistics?universityId=',
            organizationStatisticsReportUrl: 'https://ilmtec.retool.com/p/organiza-statistics?organizationId=',
        }
    },
    GOOGLE_MAPS_CONFIG: {
        apiKey: 'AIzaSyA4XRbQBcxlh9aYk0IHksrVV6QQJ1sS4QY',
        mapsUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places&loading=async&key='
    },
    linkedInAuthConfig: {
        authUri: `https://www.linkedin.com/oauth/v2/authorization`,
        redirectUri: 'https://hireos.talenlio.com/auth/callback',
        clientId: '77gt2gki02vax0',
        clientSecret: 'St50veE91TJdnd76',
        responseType: 'code',
        scope: 'r_basicprofile openid profile email'
    }
};
